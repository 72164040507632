<template>
  <main class="main">
    <div class="my_container">
      <div class="the_form_wrapper">
        <div class="the_image">
          <v-img v-if="disctop" class="desctop" src="@/assets/images/header-desktop.png"/>
          <v-img v-else class="mobile" src="@/assets/images/header-mobile.png"/>
        </div>
        <div class="the_form">
          <the-form/>
        </div>
      </div>
    </div>
    <Footer/>
  </main>
</template>
<script>
import HomePage from "@/components/HomePage";
import TheForm from "@/components/theForm";
import Footer from "@/components/layout/Footer";
export default {
  components:{Footer, TheForm, HomePage},
  data:() => ({
    disctop: null,
    isActive: false
  }),
  mounted() {
    window.onresize = () => {
     if(window.innerWidth >= 506) {
       this.disctop = true;
     } else {
       this.disctop = false;
     }
    }
  },
  created() {
    if(window.innerWidth >= 506) {
      this.disctop = true;
    } else {
      this.disctop = false;
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  background-image: url("~@/assets/images/bg.png");
  background-size: cover;
  .the_form {
    background: #585858;
    direction: rtl;
    padding: 20px;
    @media screen and (max-width: 508px) {
      padding: 20px 4px;
    }
  }

  .mobile {
    @media screen and (min-width: 508px) {
      display: none;
    }
  }

  .desctop {
    @media screen and (max-width: 508px) {
      display: none;
    }
  }
}
</style>